import '../css/doc.css';
import Privacy from './dynamic/Privacy'


const PrivacyComponent = () => {
  return (
    <>
      <div className="container mt-5 content-center">
        <hr></hr>
      <Privacy />
      <hr></hr>
      </div>
    </>
  );
};

export default PrivacyComponent;
