import React from "react";

export const ReviewsSection = () => (
    <section className="container mx-auto text-center py-5" style={{backgroundColor: '#e95d80'}}>
        <h2 className="text-white mb-4" style={{fontSize: '40px', fontWeight: 'bold'}}>We value our customers
            feedback to improve our services and provide them with an endless experience on our platform</h2>

        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center" style={{gap: '20px'}}>
            <div className="review-card"
                 style={{maxWidth: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '10px'}}>
                <p style={{fontStyle: 'italic'}}>"I found my life partner on this app! The process was so smooth and
                    respectful to our Islamic values."</p>
                <strong>- Alizey Shah</strong>
            </div>

            <div className="review-card"
                 style={{maxWidth: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '10px'}}>
                <p style={{fontStyle: 'italic'}}>"The chaperone feature gave me the confidence to chat openly, knowing
                    my privacy is protected."</p>
                <strong>- Muhammad Omar</strong>
            </div>

            <div className="review-card"
                 style={{maxWidth: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '10px'}}>
                <p style={{fontStyle: 'italic'}}>"Amazing filters! I could easily find people who share my sect and
                    values."</p>
                <strong>- Ali Reza</strong>
            </div>
        </div>
    </section>
);