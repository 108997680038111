import React, { useState } from 'react';
import {submitMessage} from "../context/ContactUs";

export default function ContactUsComponent() {
    const [formData, setFormData] = useState({
        username: '',
        fullname: '',
        email: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(null);

        try {
            await submitMessage(formData);
            setSuccess(true);
            setFormData({ username: '', fullname: '', email: '', message: '' }); // Clear the form
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="py-5" style={{ background: "whitesmoke" }}>
            <h1 className="text-center" style={{ fontWeight: "bold", color: '#e95d80', textShadow: "1px 1px 2px white" }}>
                Contact Us
            </h1>
            <div className="container">
                <p style={{ fontSize: "24px", backgroundColor: 'whitesmoke', color: 'black', fontWeight: 'bold', textAlign: "center" }}>
                    Can't find your query here? Don't worry! Just contact us, we're always here for you.
                </p>
            </div>

            {/* Contact Form */}
            <form onSubmit={handleSubmit} className="container mt-4">
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input type="text" className="form-control" id="username" name="username" value={formData.username} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="fullname" className="form-label">Full Name</label>
                    <input type="text" className="form-control" id="fullname" name="fullname" value={formData.fullname} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea className="form-control" id="message" name="message" rows="6" value={formData.message} onChange={handleChange} required />
                </div>
                <button type="submit" className="btn btn-danger mt-3 fw-bold" style={{ borderRadius: '24px', background: "#e95d80", border: "2px dotted #e95d80", maxWidth: "100%", padding: "10px 40px 10px 40px", fontSize: "14px" }}>
                    Submit
                </button>
                {/* Loading State */}
                {loading && <p className="text-center">Submitting message...</p>}

                {/* Success Message */}
                {success && <p className="text-center text-success">Hey there! Your message has been received loud and clear. We'll respond as soon as we can.</p>}

                {/* Error Message */}
                {error && <p className="text-center text-danger">Error: {error.message}</p>}
            </form>

            {/* Contact Information */}
            <div className="container mt-5 text-center">
                <p>
                    <strong>Email:</strong> support@mjmarry.com<br />
                    <strong>Phone:</strong> +923350011145<br />
                    <strong>Address:</strong> Apt. 927 9809 Loyd Groves, North Glendorafurt, UT 79600
                </p>
            </div>
        </div>
    );
}
