import React, { useState } from 'react';
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';
import {useAuth} from "../../context/AuthContext";

const AdminComponent = () => {
    const {getCurrentUser} = useAuth();
    getCurrentUser().then(usr => {
        console.log(usr)
    }).then(err => {
        console.log(err)
    })
    return (
        <Container fluid className="admin-container">

        </Container>
    );
};

export default AdminComponent;
