import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/home/HomeComponent";
import NavBar from "./navbar/Navbar";
import "./css/bootstrap/bootstrap.css";
import ProtectedRoute from "./ProtectedRoute";
import { Footer } from "./pages/Footer";
import HelpComponent from "./pages/HelpComponent";
import FAQComponent from "./pages/FAQComponent";
import GoldComponent from "./pages/GoldComponent";
import SafetyComponent from "./pages/SafetyComponent";
import PrivacyComponent from "./pages/PrivacyComponent";
import ContactUsComponent from "./pages/ContactUsComponent";
import BehaviorComponent from "./pages/BehaviourComponent";
import CookiesComponent from "./pages/dynamic/Cookies";
import TermsAndConditionsComponent from "./pages/TermsAndConditionsComponent";
import { useEffect } from "react";
import LoginComponent from "./pages/LoginComponent";
import AdminComponent from "./pages/dashboard/AdminComponent";
import { AuthProvider } from "./context/AuthContext";
import NavBarAdmin from "./pages/dashboard/navbar/Navbar";
import { AdminNav } from "./pages/dashboard/navbar/AdminNavbar";
import { ThemeProvider } from "@mui/material";
import { theme } from "./pages/Theme/theme";
import VerifyAccounts from "./pages/dashboard/VerifyAccount";
import AllUsers from "./pages/dashboard/AllUsers";

function App() {
  useEffect(() => {
    document.title = "MJMarry";
  }, []);

  const location = useLocation();
  const isDashboard = location.pathname.startsWith("/dashboard");

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <div
          className="App"
          style={{ overflowX: "hidden", minHeight: "100vh", background: "white" }}
        >
          {/* {isDashboard ? <NavBarAdmin/> : <NavBar />} */}
          {isDashboard ? <AdminNav /> : <NavBar />}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<HomePage />} />
            <Route path="/help" element={<HelpComponent />} />
            <Route path="/gold" element={<GoldComponent />} />
            <Route path="/faq" element={<FAQComponent />} />
            <Route path="/safety" element={<SafetyComponent />} />
            <Route path="/privacy" element={<PrivacyComponent />} />
            <Route path="/contactus" element={<ContactUsComponent />} />
            <Route path="/behaviour" element={<BehaviorComponent />} />
            <Route path="/cookies" element={<CookiesComponent />} />
            <Route
              path="/termsandconditions"
              element={<TermsAndConditionsComponent />}
            />
            <Route path="/login" element={<LoginComponent />} />
            <Route path="/dashboard" element={<ProtectedRoute />}>
                 
            <Route exact path="/dashboard" element={<AdminComponent />} />
            <Route  exact path="/dashboard/verify-accounts" element={<VerifyAccounts />} />
            <Route  exact path="/dashboard/all-users" element={<AllUsers />} />

               </Route>  
          </Routes>
          {isDashboard ? null : <Footer />}
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
