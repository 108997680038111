import React from 'react';
import '../../index.css';
import FAQComponent from './FAQComponent';

const SAFETY_PHONE_IMAGE_320_WEBP = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_320.webp'
const SAFETY_PHONE_IMAGE_640_WEBP = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_640.webp'
const SAFETY_PHONE_IMAGE_768_WEBP = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_768.webp'
const SAFETY_PHONE_IMAGE_1024_WEBP = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_1024.webp'
const SAFETY_PHONE_IMAGE_1280_WEBP = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_1280.webp'
const SAFETY_PHONE_IMAGE_1536_WEBP = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_1536.webp'
const SAFETY_PHONE_IMAGE_320_JPG = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_320.jpg'
const SAFETY_PHONE_IMAGE_640_JPG = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_640.jpg'
const SAFETY_PHONE_IMAGE_768_JPG = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_768.jpg'
const SAFETY_PHONE_IMAGE_1024_JPG = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_1024.jpg'
const SAFETY_PHONE_IMAGE_1280_JPG = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_1280.jpg'
const SAFETY_PHONE_IMAGE_1536_JPG = 'https://mjmarry.blob.core.windows.net/assets/safety/hero-center_1536.jpg'
const SAFETY_PIC = 'https://mjmarry.blob.core.windows.net/assets/safety/safety.jpg'
const BEHAVIOUR_PIC = 'https://mjmarry.blob.core.windows.net/assets/safety/behaviour.jpg'
const PROFILE_PIC = 'https://mjmarry.blob.core.windows.net/assets/safety/profile.jpg'

const SafetyCenter = () => (
    <section className="position-relative text-center">
        <picture>
            <source media="(max-width: 639px)" srcSet={SAFETY_PHONE_IMAGE_320_WEBP} type="image/webp" />
            <source media="(max-width: 767px)" srcSet={SAFETY_PHONE_IMAGE_640_WEBP} type="image/webp" />
            <source media="(max-width: 1023px)" srcSet={SAFETY_PHONE_IMAGE_768_WEBP} type="image/webp" />
            <source media="(max-width: 1279px)" srcSet={SAFETY_PHONE_IMAGE_1024_WEBP} type="image/webp" />
            <source media="(max-width: 1535px)" srcSet={SAFETY_PHONE_IMAGE_1280_WEBP} type="image/webp" />
            <source media="(min-width: 1537px)" srcSet={SAFETY_PHONE_IMAGE_1536_WEBP} type="image/webp" />
            <source media="(max-width: 639px)" srcSet={SAFETY_PHONE_IMAGE_320_JPG} type="image/jpeg" />
            <source media="(max-width: 767px)" srcSet={SAFETY_PHONE_IMAGE_640_JPG} type="image/jpeg" />
            <source media="(max-width: 1023px)" srcSet={SAFETY_PHONE_IMAGE_768_JPG} type="image/jpeg" />
            <source media="(max-width: 1279px)" srcSet={SAFETY_PHONE_IMAGE_1024_JPG} type="image/jpeg" />
            <source media="(max-width: 1535px)" srcSet={SAFETY_PHONE_IMAGE_1280_JPG} type="image/jpeg" />
            <source media="(min-width: 1537px)" srcSet={SAFETY_PHONE_IMAGE_1536_JPG} type="image/jpeg" />
            <img className="img-fluid w-100" src={SAFETY_PHONE_IMAGE_1280_JPG} alt="Safety Center" loading="eager"
                style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} />
        </picture>
        <div
            className="custom-style position-absolute top-50 start-0 translate-middle-y d-flex flex-column justify-content-left align-items-left "
            style={{ marginLeft: "10rem", maxWidth: "100%" }}
        >
            <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'left', color: "whitesmoke" }}>SAFETY CENTER
            </h1>
            <p className="text-white" style={{ fontSize: '18px', fontWeight: "bold", textAlign: 'justify' }}>
                Your safety is our top priority. The guidelines we set are there just for you so that you can find your partner safely!
            </p>
            <div className="justify-content-left align-items-left" style={{ marginTop: "-10px", marginLeft: "-10rem" }}>
                <a href="/contact" target="_self" className="btn btn-danger mt-3 fw-bold"
                    style={{ borderRadius: '24px', background: "#e95d80", border: "2px dotted #e95d80", maxWidth: "100%", padding: "10px 40px 10px 40px", fontSize: "14px" }}>
                    Contact Support
                </a>
            </div>
        </div>
    </section>
);

const sectionsData = [
    {
        id: "profile",
        title: "Profile Guidelines",
        description: "Follow our Guidelines for your own Safety! Set-up your profile and get your ID and Selfie verified.",
        images: {
            jpeg: [PROFILE_PIC]
        }
    },
    {
        id: "behaviour",
        title: "Behaviour Guidelines",
        description: "Be at your best behaviour and keep it Halal.",
        images: {
            jpeg: [BEHAVIOUR_PIC]
        }
    },
    {
        id: "safety",
        title: "Safety Guidelines",
        description: "Contact us if you find anything worrisome and report any wrongful or suspicious activities!",
        images: {
            jpeg: [SAFETY_PIC]
        }
    },
]

const Section = ({ title, description, images, index }) => (
    <section
        className="container mx-auto d-flex flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-left"
        style={{ gap: '100px', padding: '4rem 0' }}>



        <div style={{ maxWidth: '500px' }}>
            <picture >
                <source media="(max-width: 639px)" srcSet={images.jpeg[0]} type="image/jpeg" />
                <img style={{ boxShadow: "0px 4px 40px black" }} className="d-block w-100" src={images.jpeg[0]} alt=""
                    loading="lazy" />
            </picture>
        </div>

        <div style={{ maxWidth: '400px' }}>
            <h2 style={{
                fontSize: '40px',
                fontWeight: 'bold',
                lineHeight: '48px',
                letterSpacing: '-1px',
                marginTop: '20px'
            }}>{title}</h2>
            <div style={{ fontSize: '18px', lineHeight: '27px', fontWeight: 'light' }}
                dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>

    </section>
);


export default function SafetyComponent() {
    return (
        <>
            <div class="">
                <SafetyCenter />
                <div className='row'>
                    {sectionsData.map((section, index) => (
                        <Section key={section.id} {...section} index={index} />

                    ))}
                </div>
                <hr>
                </hr>

                <h1 className="text-center mb-4" style={{ color: '#e95d80' }}>Popular Questions</h1>
                <FAQComponent />
                <div className="container mb-5">
                    <p style={{ fontSize: "24px", backgroundColor: 'white', color: 'black', fontWeight: 'bold', textAlign: "center" }}> Your Question is not here? Please check out <a href="/help" style={{ color: "#e95d80" }}>Help & Support</a> </p>
                </div>

                <div className="py-5" style={{ background: "#e95d80" }}>
                    <h1 className="text-center" style={{ color: 'white' , textShadow:"0px 1px 2px white"}}>Feeling Unsafe?</h1>
                    <div className="container">
                        <p style={{ fontSize: "24px", backgroundColor: '#e95d80', color: 'white', fontWeight: 'bold', textAlign: "center" }}> If you are feeling unsafe, call <a href="tel:911" style={{fontSize:"36px"}}><span style={{ color: "black" }}>9</span><span style={{ color: "black" }}>11</span></a> A.S.A.P. </p>
                    </div>
                </div>
            </div>
        </>
    );
}
