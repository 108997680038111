import logo from "../../pictures/logo.svg";
import React from "react";
import ARROW_ICON_URL from "../../../assets/icons/downArrow.png"
import APP_STORE_ICON_URL from "../../../assets/icons/apple/app-store-badge.svg"
import GOOGLE_PLAY_ICON_URL from "../../../assets/icons/play/google-play-badge.svg"

const APP_STORE_URL = "https://api.mjmarry.com/appstore";
const GOOGLE_PLAY_URL = "https://api.mjmarry.com/playstore";


//const DateVideo = "https://mjmarry.blob.core.windows.net/assets/videos/date_3840_2160_25fps.mp4"
//const DinnerPortraitVideo = "https://mjmarry.blob.core.windows.net/assets/videos/dinner_1080_1920_25fps.mp4"
//const DinnerLandscapeVideo = "https://mjmarry.blob.core.windows.net/assets/videos/dinner_1920_1080_25fps.mp4"
const PhotographyVideo = "https://mjmarry.blob.core.windows.net/assets/videos/photography_3840_2160_25fps.mp4"
//const WalkingVideo = "https://mjmarry.blob.core.windows.net/assets/videos/walking_3840_2160_25fps.mp4"
const PhotographyWebm = "https://mjmarry.blob.core.windows.net/assets/videos/photography_3840_2160_25fps.webm"

export function HeroSection() {
    return (
        <section className="position-relative d-flex justify-content-center align-items-center vh-100 overflow-hidden">
            <video style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                objectFit: 'cover',
                zIndex: -1
            }} autoPlay loop muted playsInline>
                <source src={PhotographyVideo} type="video/mp4"/>
                <source src={PhotographyWebm} type="video/webm"/>
            </video>
            <div className="content position-absolute text-center"
                 style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <img src={logo} alt="Logo" style={{maxHeight: '140px'}}/>
                <h2 className="text-white" style={{fontSize: '48px', lineHeight: '40px', letterSpacing: '-2px'}}>
                    Where Muslims Just Marry
                </h2>
                <p className="text-white" style={{fontSize: '14px'}}>
                    "And of His signs is that He created for you from yourselves mates that you may find tranquillity in
                    them;
                    and He placed between you affection and mercy. Indeed in that are signs for a people who give
                    thought.” - Quran 30:21
                </p>
                <div className="mt-3 d-flex justify-content-center">
                    <a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer" className="me-2">
                        <img src={APP_STORE_ICON_URL} alt="App Store icon" style={{width: '150px'}}/>
                    </a>
                    <a href={GOOGLE_PLAY_URL} target="_blank" rel="noopener noreferrer">
                        <img src={GOOGLE_PLAY_ICON_URL} alt="Google Play icon" style={{width: '150px'}}/>
                    </a>
                </div>
            </div>
            <div className="position-absolute bottom-0 mb-2 w-100 text-center">
                <a href="#connecting-muslims">
                    <img src={ARROW_ICON_URL} alt="Continue to main content arrow"
                         style={{height: '35px', cursor: 'pointer'}}/>
                </a>
            </div>
        </section>
        
    );
}