import * as React from 'react';
import logo from '../pictures/logo.svg'
import '../css/footer2.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export function Footer() {
    return (
        <>
            <hr></hr>
            <footer class="footer-dark" style={{ background: "" }}>
                <div class="container">
                    <div className='row' style={{ background: "" }}>
                        <div class="col-md-4 item text">
                            <div className='row center_vertical'>
                                <h3 class="col-md-12 " style={{ textAlign: "center", fontSize: "36px" }}> <img
                                    src={logo}
                                    width="60"
                                    height="60"
                                    className="d-inline-block align-center"
                                    alt="Logo"
                                    style={{ marginLeft: '1rem', marginRight: '1rem' }}
                                />MJMarry</h3>
                            </div>
                        </div>
                        <div class="col-md-6 text-justify" style={{ fontSize: "18px" }}>
                            <p>
                                Founded on the principles of diversity, respect, and privacy, MJMarry is more than just a matrimonial platform –
                                it's a community where people from various walks of life can come together to build meaningful connections and embark on their journey towards lifelong companionship.
                            </p>
                        </div>
                        <div class="col-sm-2 col-md-2" style={{background: ""}}>
                            <div class="col item social" style={{ marginTop: "2rem", background: "" }}>
                                <a class="icon_link" href="#" style={{background: "" }}>
                                <FontAwesomeIcon icon="fa-brands fa-facebook" />
                                </a>
                                <a class="icon_link" href="#">
                                    <i class="icon fa-brands fa-instagram fa-fw">
                                    </i>
                                </a>
                                <a class="icon_link" href="#">
                                    <i class="icon fa-brands fa-snapchat fa-fw">
                                    </i>
                                </a>
                                <a class="icon_link" href="#">
                                    <i class="icon fa-brands fa-twitter fa-fw">
                                    </i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ background: "", color: "black" }}>
                    <div class="container">
                        <div class="row" style={{ marginTop: "", background: "" }}>
                            <div class="col-sm-3 col-md-3 item">

                                <h3>Company</h3>
                                <ul style={{ color: "black" }}>
                                    <li><a href="/about">Home</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-3 col-md-3 item">

                                <h3>Product</h3>
                                <ul style={{ color: "black" }}>
                                    <li><a href="#">Sign Up</a></li>
                                    <li><a href="/about">Feature List</a></li>
                                    <li><a href="/gold" style={{ color: "#ccac00" }}>MJMarry Gold</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-3 col-md-3 item">

                                <h3>Help & Support</h3>
                                <ul>
                                    <li><a href="/help">Help & Support</a></li>
                                    <li><a href="/safety">Safety</a></li>
                                    {/*<li><a href="#">Contact</a></li>*/}
                                    <li><a href="/behaviour">Behaviour</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-3 col-md-3 item">

                                <h3>Legal</h3>
                                <ul>
                                    <li><a href="/termsandconditions">Terms and Conditions</a></li>
                                    <li><a href="/privacy">Data Privacy</a></li>
                                    <li><a href="/cookies">Cookies</a></li>
                                </ul>
                            </div>
                        </div>


                        <p class="copyright">&copy; 2024 MJMarry. All rights reserved.</p>
                    </div>
                </div>
            </footer>
            {/*
            <footer class="footer" style={{ textAlign: "center", background: "white", color: "black", marginTop: "20px" }}>
                <div class="footer__addr">
                    <h1 class="footer__logo"> <img
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top"
                        alt="Logo"
                        style={{ marginLeft: '1rem', marginRight: '1rem' }}
                    />MJMarry</h1>

                    <h2>Contact</h2>

                    <address>
                        5534 Somewhere In. The World 22193-10212<br />

                        <Button
                            variant="custom-btn"
                            size="md"
                            active
                            className=""

                            style={{
                                marginTop: '1rem', fontWeight: "bold", background: "#e95d80",
                                color: "white",
                                border: "2px solid #e95d80",
                                borderRadius: "3px",
                                padding: "5px 10px",
                            }}
                        >
                            Email Us
                        </Button>


                    </address>
                </div>

                <ul class="footer__nav">
                    <li class="nav__item">
                        <h2 class="nav__title"></h2>

                    
                            <div class="col-md-12 item text">
                            <h3 class="footer__logo"> <img
                                src={logo}
                                width="50"
                                height="50"
                                className="d-inline-block align-top"
                                alt="Logo"
                                style={{ marginLeft: '1rem', marginRight: '1rem' }}
                            />MJMarry</h3>
                            <p>
                                Founded on the principles of diversity, respect, and privacy, MJMarry is more than just a matrimonial platform –
                                it's a community where people from various walks of life can come together to build meaningful connections and embark on their journey towards lifelong companionship.
                            </p></div>
                        
                    </li>



                    <li class="nav__item">
                        <h2 class="nav__title">Socials</h2>

                        <ul class="nav__ul">
                            <li>
                                <a hrep="#" style={{ color: "black" }}> <i class="fa-brands fa-facebook fa-fw"></i>  Facebook</a>
                            </li>

                            <li>
                                <a href="#" style={{ color: "black" }}> <i class="fa-brands fa-instagram fa-fw"></i>  Instagram</a>
                            </li>

                            <li>
                                <a href="#" style={{ color: "black" }}><i class="fa-brands fa-twitter fa-fw"></i>  Twitter</a>
                            </li>
                        </ul>
                    </li>

                </ul>

                <div class="legal" style={{ paddingLeft: '9rem', paddingRight: "9rem" }}>
                    <p>&copy; 2024 MJMarry. All rights reserved.</p>

                    <div class="legal__links">
                        <span>Made with <span class="heart">♥</span> from USA</span>
                    </div>
                </div>
            </footer>
                        */}
        </>
    );
};