import { React, useState, useEffect }from 'react';
import {HeroSection} from "./HeroSection";
import {ReviewsSection} from "./ReviewsSection";

const pic1 = "https://mjmarry.blob.core.windows.net/assets/about/1.jpg"
const  pic2 =   "https://mjmarry.blob.core.windows.net/assets/about/2.jpg"
const  pic3 = "https://mjmarry.blob.core.windows.net/assets/about/3.jpg"
const  pic4 = "https://mjmarry.blob.core.windows.net/assets/about/4.jpg"
const  pic5 = "https://mjmarry.blob.core.windows.net/assets/about/5.jpg"

const sectionsData = [
    {
        id: "feature",
        title: "The App for all Muslims Worldwide",
        description: "<i class='fi fi-rr-globe'></i> Growing Community <br/> <i class='fi fi-rr-people'></i> Growing Successes",
        images: {
            png: [pic1]
        }
    },
    {
        id: "chat",
        title: "Chat for Free",
        description: "Discover your perfect match, chat, and find love on MJMarry – all without any cost!",
        images: {
            png: [pic2]
        }
    },
    {
        id: "video",
        title: "Free Video Calling",
        description: "Take control of your connections and keep your privacy intact - on MJMarry, you decide who you can call without ever revealing your phone number.",
        images: {
            png: [pic3]
        }
    },
    {
        id: "privacy",
        title: "Complete Privacy",
        description: "Protect your identity with ease on MJMarry – conceal your photos and adopt a nickname to stay incognito from friends and family.",
        images: {
            png: [pic4]
        }
    },
    {
        id: "religion",
        title: "Religious Filters",
        description: "Respect for diversity is essential. On MJMarry, you can refine your search for potential partners by various preferences, ensuring compatibility, and shared values without compromising respect for different backgrounds and beliefs.",
        images: {
            png: [pic4]
        }
    },
    {
        id: "chaperones",
        title: "Chaperones",
        description: "Enhance your peace of mind by involving a trusted chaperone, known as a Wali, in your conversations on MJMarry, ensuring a safe and respectful environment for all users.",
        images: {
            png: [pic5]
        }
    }
];



const Section = ({ title, description, images, index, width }) => (
    <section
        className="container mx-auto d-flex flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-left"
        style={{ gap: '100px', padding: '4rem 0', }}>

        {width <= "576" && <>

            <div style={{ maxWidth: '300px' }}>
                <h2 style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    lineHeight: '48px',
                    letterSpacing: '-1px',
                    marginTop: ''
                }}>{title}</h2>
                <div style={{ fontSize: '18px', lineHeight: '27px', fontWeight: 'light' }}
                    dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>

            <div style={{ maxWidth: '400px' }}>
                <picture>
                    <source media="(max-width: 1535px)" srcSet={images.png[0]} type="image/png" />
                    <img className="d-block w-100" src={images.png[0]} alt={title} loading="lazy"
                        style={{ objectFit: 'cover', objectPosition: '70%' }} />
                </picture>
            </div>

        </>}

        {width > "576" && <>
            {index % 2 !== 0 && <div style={{ maxWidth: '400px' }}>
                <h2 style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    lineHeight: '48px',
                    letterSpacing: '-1px',
                    marginTop: '20px'
                }}>{title}</h2>
                <div style={{ fontSize: '18px', lineHeight: '27px', fontWeight: 'light' }}
                    dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>}

            <div style={{ maxWidth: '500px' }}>
                <picture>
                    <source media="(max-width: 1535px)" srcSet={images.png[0]} type="image/png" />
                    <img className="d-block w-100" src={images.png[0]} alt={title} loading="lazy"
                        style={{ objectFit: 'cover', objectPosition: '70%' }} />
                </picture>
            </div>

            {index % 2 === 0 && <div style={{ maxWidth: '400px' }}>
                <h2 style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    lineHeight: '48px',
                    letterSpacing: '-1px',
                    marginTop: '20px'
                }}>{title}</h2>
                <div style={{ fontSize: '18px', lineHeight: '27px', fontWeight: 'light' }}
                    dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>}
        </>}

    </section>
);


export default function HomePage() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <HeroSection />
            <div style={{ background: "white" }}>
                <div>
                    {sectionsData.map((section, index) => (
                        <Section key={section.id} {...section} index={index} width={width} />
                    ))}
                </div>
                <ReviewsSection />
            </div>

        </>
    );
}
