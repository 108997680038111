import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import {FAQs} from "./data/HelpQuestions"

export default function FAQComponent() {

  return (
    <>
      <div className="container mt-5">
        <hr></hr>
        <Accordion defaultActiveKey="0">
          {FAQs.map((faq, index) => (
            <Accordion.Item eventKey={index}>
              <Accordion.Header className="ml-0 mt-0 fw-bold"> <span style={{fontWeight: "bold"}}>{index + 1}. {faq.question}</span></Accordion.Header>
              <Accordion.Body><span style={{ backgroundColor: 'white', color: 'black', fontWeight: 'bold' }}>Answer: </span><br></br><span style={{ marginLeft: "30px" }}>{faq.response}</span></Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <hr></hr>
      </div>
    </>
  );
}
