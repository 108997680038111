import React from 'react';
import { useNavigate } from 'react-router-dom';
import FAQComponent from './FAQComponent';
import ContactUsComponent from './ContactUsComponent';

export default function HelpComponent() {
  const navigate = useNavigate();


  return (
    <>
      <div className=" mt-5">
        <h1 className="text-center mb-4" style={{ color: '#e95d80' }}>Frequently Asked Questions</h1>
        <FAQComponent />
        <ContactUsComponent />
      </div>
    </>
  );
}
