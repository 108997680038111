import { React, useEffect, useState } from 'react';
import '../../index.css'

const GOLD_PHONE_IMAGE_320_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_320.png'
const GOLD_PHONE_IMAGE_640_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_640.png'
const GOLD_PHONE_IMAGE_768_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_768.png'
const GOLD_PHONE_IMAGE_1024_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1024.png'
const GOLD_PHONE_IMAGE_1280_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1280.png'
const GOLD_PHONE_IMAGE_1536_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1536.png'

const GoldMembershipSection = () => (
    <section className="position-relative text-center">
        <picture>
            <source media="(max-width: 639px)" srcSet={GOLD_PHONE_IMAGE_320_JPG} type="image/jpeg" />
            <source media="(max-width: 767px)" srcSet={GOLD_PHONE_IMAGE_640_JPG} type="image/jpeg" />
            <source media="(max-width: 1023px)" srcSet={GOLD_PHONE_IMAGE_768_JPG} type="image/jpeg" />
            <source media="(max-width: 1279px)" srcSet={GOLD_PHONE_IMAGE_1024_JPG} type="image/jpeg" />
            <source media="(max-width: 1535px)" srcSet={GOLD_PHONE_IMAGE_1280_JPG} type="image/jpeg" />
            <source media="(min-width: 1537px)" srcSet={GOLD_PHONE_IMAGE_1536_JPG} type="image/jpeg" />
            <img className="img-fluid w-100" src={GOLD_PHONE_IMAGE_1536_JPG} alt="Gold Membership" loading="eager"
                style={{ height: '100vh', objectFit: 'cover' }} />
        </picture>

        <div className=''>
            <div
                className="custom-style position-absolute top-50 start-0 translate-middle-y d-flex flex-column justify-content-left align-items-left "
                style={{ marginLeft: "10rem", maxWidth: "100%" }}
            >
                <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'left', color: "whitesmoke" }}>Behavior 
                </h1>
                <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'left', color: "whitesmoke" }}>Guidelines
                </h1>
                <p className="text-white" style={{ fontSize: '18px', textAlign: 'justify' }}>
                    We have high expectations of you. Follow our guide to be the best of you on the platform!
                </p>
                <div className="justify-content-center align-items-center ">
                    
                </div>
            </div>
        </div>
    </section>
);

export default function BehaviorComponent() {
    return (
        <>
            <GoldMembershipSection />
            <div className='mt-5 mx-5'>
                <section class="text-center" style={{ background: "white"}}>
                    <h1 style={{fontWeight: "bold", fontSize: "48px"}}>Maintain your taqwa online and offline</h1>
                    <div className="row d-flex flex-row justify-content-left align-items-left "
                >
                        <div className='col-md-6 col-sm-6' >
                            <p style={{fontSize: "24px",}}><span style={{ fontWeight: "bold" }}>
                                We have clear examples of what we all should strive for in the Quran and the Holy Prophet (SAW):
                            </span></p>
                            <br></br>
                            <p style={{fontSize: "36px"}}>
                            <span style={{ color: "#e95d80" }}>
                                وَإِنَّكَ لَعَلَى‏ خُلُقٍ عَظِيمٍ‏
                            </span></p>
                            <br></br>
                            <p style={{fontSize: "24px"}}> “And most surely you conform (yourself) to sublime morality” <span style={{ fontWeight: "normal" }}>(Quran 68:4)</span></p>
                        </div>
                        <div className='col-md-6 col-sm-6'>
                           <p style={{fontSize: "24px"}}> We pray you’ll maintain your taqwa both on and off the app. Any inappropriate behavior could result in a <span style={{ fontWeight: "bold" }}>
                                permanent ban</span>.</p>

                            <ul style={{fontSize: "24px", textAlign: "left"}}>
                                <li>Keep all conversations on MJMarry so we can do our job keeping you safe</li>
                                <li>Report any behavior that breaks our guidelines - we investigate 
                                    <span style={{ fontWeight: "bold", margin: "8px" }}>
                                        EVERY
                                    </span>
                                    report</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
