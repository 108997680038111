import React, { useEffect, useState } from "react";
import { Container, CircularProgress, Alert, Button, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {SERVER} from "../../../Constants"

const drawerWidth = 300;

const getUsers = async () => {
  const apiClient = axios.create({
    baseURL: SERVER,
    withCredentials: true,
  });
  const response = await apiClient.get("user/getUsers");
  return response.data;
};

// Function to format the date
const formatDate = (createdAt) => {
  try {
    const date = new Date(createdAt);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid timestamp: ' + createdAt);
    }
    const options = { day: '2-digit', weekday: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return 'Invalid date';
  }
};

// Function to prepare chart data
const prepareChartData = (data) => {
  const dateMap = data.reduce((acc, user) => {
    const date = formatDate(user.createdAt);
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  return Object.keys(dateMap).map(date => ({
    date,
    count: dateMap[date]
  }));
};

// Main component
const AllUsers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUsers();
        console.log("Users fetched data:", result); // Debugging log
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error); // Debugging log
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const columns = [
    { field: "username", headerName: "Username", width: 150, headerClassName: 'header-class' },
    { field: "fullname", headerName: "Fullname", width: 200, headerClassName: 'header-class' },
    { field: "tier", headerName: "Tier", width: 150, headerClassName: 'header-class' },
    { field: "emailVerified", headerName: "Email Verified", width: 150, headerClassName: 'header-class' },
    { field: "createdAt", headerName: "Date", width: 150, headerClassName: 'header-class', 
      renderCell: (params) => formatDate(params.value) 
    },
    { field: "email", headerName: "Email", width: 200, headerClassName: 'header-class' },
    { field: "status", headerName: "Status", width: 150, headerClassName: 'header-class' },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: 'header-class',
      width: 150,
      renderCell: (params) => {
        const status = params.row.status === "enabled"; // Change this logic based on your actual status field
        return (
          <Button variant="contained" color={status ? "secondary" : "primary"}>
            {status ? "Disable" : "Enable"}
          </Button>
        );
      },
    },
  ];

  const chartData = prepareChartData(data);

  return (
    <Container
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        mt: 20,
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
         <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', mt: 5,mb:10 }}>
            Users Joined Over Time
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#E95D80" />
            </BarChart>
          </ResponsiveContainer>
           <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', mt: 20, mb: 5 }}>
            Users
          </Typography>
          <Box sx={{
            height: '100%',
            width: '100%',
            '& .header-class': {
              backgroundColor: '#E95D80',
              color: '#fff',
            },
          }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              getRowId={(row) => row._id}
            />
          </Box>
         
        </>
      )}
    </Container>
  );
};

export default AllUsers;
