import axios from "axios";
import {SERVER} from "../../Constants";

const apiClient = axios.create({
    baseURL: SERVER,
    withCredentials: true
});

export const submitMessage = async (data) => {
    try {
        const response = await apiClient.post('/support/submitMessage', data);
        console.log("API response:", response); // Debugging log
        return response.data;
    } catch (error) {
        console.error("API error:", error.response); // Debugging log
        throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
};
