import React, {useContext, useState, useEffect} from "react"
import axios from "axios";
import {SERVER} from "../../Constants.js"

const AuthContext = React.createContext()
export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        axios({
            method: "get",
            withCredentials: true,
            url: `${SERVER}/auth/user`,
        }).then ( (res) => {
            console.log(res)
            setCurrentUser(res.data.user)
        })
    }, [1])

    function login(username, password) {
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                data: {
                    identifier: username,
                    password: password,
                },
                withCredentials: true,
                url: `${SERVER}/auth/login`,
            }).then((res) => {
                setCurrentUser(res.data.user)
                resolve(res.data.user)
            }).catch(reject)
        })
    }
    async function getCurrentUser(){
        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                withCredentials: true,
                url: `${SERVER}/auth/user`,
            }).then ( (res) => {
                console.log(res)
                setCurrentUser(res.data.user)
                resolve(res.data.user)
            }).catch(reject)
        })
    }

    function logout() {
        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                withCredentials: true,
                url: `${SERVER}/auth/logout`,
            }).then ( (res) => {
                setCurrentUser(res.data.user)
                resolve();
            }).catch(err => {
                reject(err)
            })
        })
    }


    useEffect(() => {
        /*const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })
*/
        //return unsubscribe
    }, [])

    const value = {
        currentUser,
        setCurrentUser,
        getCurrentUser,
        login,
        logout,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}