import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Alert, Typography } from "@mui/material";
import { getPendingPhotoVerifications } from '../../context/User';
import ImageLoader from './ImageLoader ';
const drawerWidth = 300;

const VerifyAccounts = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getPendingPhotoVerifications();
                console.log("Fetched data:", result); // Debugging log
                setData(result);
            } catch (error) {
                console.error("Error fetching data:", error); // Debugging log
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const formatDate = (createdAt) => {
        try {
            const date = new Date(createdAt);
            if (isNaN(date.getTime())) {
                throw new Error('Invalid timestamp: ' + createdAt);
            }
            const options = { weekday: 'short', day: '2-digit', year: 'numeric' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        } catch (error) {
            console.error('Error formatting timestamp:', error);
            return 'Invalid date';
        }
    };

    return (
        <Container sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: 20
        }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{fontWeight:'bold',  mb:5 }}>
                Verified Accounts
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">{error}</Alert>
            ) : (
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead sx={{backgroundColor:'#E95D80'}}> 
                            <TableRow >
                                <TableCell sx={{color:'white'}}>Username</TableCell>
                                <TableCell sx={{color:'white'}}>Email</TableCell>
                                <TableCell sx={{color:'white'}}>Status</TableCell>
                                <TableCell sx={{color:'white'}}>Date</TableCell>
                                <TableCell sx={{color:'white'}}>Image</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((verification) => (
                                <TableRow key={verification.user.username}>
                                    <TableCell>{verification.user.username}</TableCell>
                                    <TableCell>{verification.user.email}</TableCell>
                                    <TableCell>{verification.status}</TableCell>
                                    <TableCell>{formatDate(verification.createdAt)}</TableCell>
                                    <TableCell>
                                        <ImageLoader source={{ uri: verification.image }} alt="Verification" style={{ width: 100, height: 100, borderRadius: '100px' }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default VerifyAccounts;
