import React, { useState } from 'react';
import {useAuth} from "../../context/AuthContext";

export default function UnAuthorized() {
    const {currentUser, login} = useAuth()

    return (
        <div className="container mt-5">
            UnAuthorized, Please Login on Mobile App
        </div>
    );
}
