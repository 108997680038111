export const FAQs = [
    {
        question: "How do I create a profile on MJMarry?",
        response: "Creating a profile is simple. Just click on the 'Sign Up' button and follow the instructions to set up your profile."
    },
    {
        question: "Is my personal information secure?",
        response: "Yes, we prioritize your privacy and security. Your personal information is kept confidential and is not shared with third parties without your consent."
    },
    {
        question: "How does the matching process work?",
        response: "Our matching process is based on your profile preferences. We suggest matches that align with your interests, values, and partner preferences."
    },
    {
        question: "Can I communicate with my matches directly?",
        response: "Yes, once you've matched with someone, you can communicate with them directly through our secure messaging platform."
    },
    {
        question: "What should I do if I encounter a problem?",
        response: "If you encounter any issues or have concerns, please contact our support team for assistance."
    },
    {
        question: "Is MJMarry free to use?",
        response: "Yes, MJMarry offers core features for free. However, we may offer premium features with additional benefits as well."
    },
    {
        question: "How can I involve a chaperone (Wali) in my conversations?",
        response: "You can involve a chaperone (Wali) in your conversations by enabling the feature in your settings. This ensures an extra layer of trust and comfort."
    },
    {
        question: "Is MJMarry available worldwide?",
        response: "No, MJMarry is only accessible to US users at the moment. It will be accessible worldwide in the near future."
    },
    {
        question: "Can I specify religious or cultural preferences in my search?",
        response: "Absolutely! MJMarry allows you to refine your search based on religious, cultural, and other preferences to find the most compatible matches."
    }
];
