import React, { useState, useRef } from "react";
import InputFile from '../../files/input.pdf';
import { useCallback } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../css/doc.css';
import TCs from './dynamic/TermsAndConditons'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
  renderParameters: {
    intent: "print"
  }
};

const maxWidth = 800;

const TermsAndConditionsComponent = () => {
  const [file, setFile] = useState('./sample.pdf');
  const [numPages, setNumPages] = useState(0);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <>
      <div className="container mt-5 content-center">
        <hr></hr>
      <TCs />
      <hr></hr>
      </div>
    </>
  );
};

export default TermsAndConditionsComponent;
