import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {SERVER} from "../../../Constants"

const CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds


const apiClient = axios.create({
    baseURL: SERVER, // Adjust the base URL if necessary
    withCredentials: true
});

const downloadSasFile = async (url) => {
    const query = { img: url };
    const response = await apiClient.get("user/getAccess", { params: query });
    return response.data;
};

const ImageLoader = ({ source, alt, style }) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const url = source.uri;

        const loadFromCache = async () => {
            try {
                const cachedItem = localStorage.getItem(url);
                if (cachedItem) {
                    const { data, lastUsed } = JSON.parse(cachedItem);
                    if (Date.now() - lastUsed < CACHE_EXPIRATION_TIME) {
                        setFile(data);
                        setLoading(false);
                    } else {
                        localStorage.removeItem(url);
                        downloadAndCacheImage(url);
                    }
                } else {
                    downloadAndCacheImage(url);
                }
            } catch (error) {
                console.error("Error loading image from cache:", error);
                downloadAndCacheImage(url);
            }
        };

        const downloadAndCacheImage = async (url) => {
            try {
                const data = await downloadSasFile(url);
                setFile(data);
                const newItem = { data, lastUsed: Date.now() };
                localStorage.setItem(url, JSON.stringify(newItem));
            } catch (error) {
                console.error("Error downloading image:", error);
            } finally {
                setLoading(false);
            }
        };

        loadFromCache();
    }, [source.uri]);

    return (
        loading ? (
            <div style={{ ...style, backgroundColor: '#ccc', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span>{alt ? alt[0] : 'Loading'}</span>
            </div>
        ) : (
            <img src={file} alt={alt} style={style} />
        )
    );
};

export default ImageLoader;
